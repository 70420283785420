define('due-backoffice/components/customer-journey/new-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      setDiff = _Ember$computed.setDiff,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    title_duplicate: false,
    classNames: ['customer-journey', 'new-form'],
    errorMessage: '',
    searchTouchpointValue: '',

    jsonStringifiedParams: computed("model.params", {
      get: function get() {
        return JSON.stringify(this.get("model.params"), null, 2);
      },
      set: function set(key, value) {
        try {
          var parsedValue = JSON.parse(value);
          this.set("model.params", parsedValue);
        } catch (e) {
          console.warn("Invalid JSON input:", e);
        }
        return value;
      }
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('model.company.id')) {
        this.get('store').query('touchpoint', { filter: { company_id: this.get('model.company.id') } }).then(function (touchpoints) {
          return _this.set('allTouchpoints', touchpoints);
        });
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.initForm();
    },
    willDestroyElement: function willDestroyElement() {
      $('#new-customer-journey-modal.ui.modal').remove();
    },
    initForm: function initForm() {
      var _this2 = this;

      var fields = {
        nom: {
          identifier: "nom",
          rules: [{
            type: 'empty',
            prompt: "Merci d'indiquer un nom pour ce customer journey"
          }]
        }
      };
      $('.ui.form').form({
        onSuccess: function onSuccess() {
          $('#new-customer-journey-modal.ui.modal').modal({
            onApprove: function onApprove() {
              return _this2.submitCustomerJourney();
            }
          }).modal('show');
          return false;
        },
        fields: fields
      });
    },
    submitCustomerJourney: function submitCustomerJourney() {
      var _this3 = this;

      this.get('model').save().then(function () {
        _this3.backToCompany();
      }).catch(function (error) {
        if (error.errors && error.errors[0].status === 422) {
          var apiErrors = JSON.parse(error.errors[0].detail);
          var errorMessage = apiErrors.tags ? apiErrors.tags.join(', ') : 'There was an error processing your request. Please check your input and try again.';
          _this3.set('errorMessage', errorMessage);
        } else {
          console.error('An unexpected error occurred:', error);
          _this3.set('errorMessage', 'There was an error processing your request. Please check your input and try again.');
        }
      });
    },
    backToCompany: function backToCompany() {
      if (this.get('model.isNew')) {
        this.get('model').deleteRecord();
      }
      this.get('router').transitionTo('companies.show', this.get('model.company.id'));
    },


    touchpoints: computed('allTouchpoints', 'searchTouchpointValue', function () {
      var searchValue = this.get('searchTouchpointValue').toLowerCase();
      var touchpoints = searchValue.length === 0 ? this.get('allTouchpoints') || [] : this.get('allTouchpoints').filter(function (t) {
        return t.get('name').toLowerCase().includes(searchValue);
      });
      return touchpoints.map(function (t) {
        return t.get('name');
      });
    }),

    actions: {
      searchTouchpoint: function searchTouchpoint(term) {
        this.set('searchTouchpointValue', term);
      },
      selectTouchpoint: function selectTouchpoint(selectedTouchpoints) {
        this.set('model.touchpoint_names', selectedTouchpoints);
      },
      backToCompany: function backToCompany() {
        this.backToCompany();
      }
    }

  });
});