define('due-backoffice/controllers/companies/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    showSegments: ['options', 'data-replicate', 'notifications', 'custom-sms-tag', 'surveys', 'custom-smileys', 'custom-stats', 'randomize-feedbacks', 'remove-feedback', 'segments', 'tags', 'dashboard-languages', 'custom-email', 'subscription', 'integrations', 'accounts', 'sso-saml', 'mapping-table', 'customer-journeys']

  });
});