define("due-backoffice/helpers/csv-to-html", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Helper.helper(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            csvString = _ref2[0];

        if (!csvString) return "";

        function parseCSV(csv) {
            var rows = [];
            var row = [];
            var current = "";
            var insideQuotes = false;

            for (var i = 0; i < csv.length; i++) {
                var char = csv[i];
                var nextChar = csv[i + 1];

                if (char === '"' && insideQuotes && nextChar === '"') {
                    // Handle double quotes inside quoted fields
                    current += '"';
                    i++; // Skip next quote
                } else if (char === '"') {
                    // Toggle quote mode
                    insideQuotes = !insideQuotes;
                } else if (char === "," && !insideQuotes) {
                    // Comma outside quotes => new column
                    row.push(current.trim());
                    current = "";
                } else if (char === "\n" && !insideQuotes) {
                    // Newline outside quotes => new row
                    row.push(current.trim());
                    rows.push(row);
                    row = [];
                    current = "";
                } else {
                    // Regular character
                    current += char;
                }
            }

            // Push last row (if any data remains)
            if (current || row.length) {
                row.push(current.trim());
                rows.push(row);
            }

            return rows;
        }

        var parsedData = parseCSV(csvString.trim());

        if (parsedData.length === 0) return "";

        var tableHtml = "\n    <table style=\"\n      width: 100%;\n      border-collapse: collapse;\n      border: 1px solid #ddd;\n      border-radius: 2px;\n      overflow: hidden;\n    \">\n      <thead style=\"background-color: #E8E8E8; color: black;\">\n        <tr>\n  ";

        var header = parsedData[0]; // First row as headers
        var body = parsedData.slice(1); // Remaining rows as body

        header.forEach(function (col) {
            tableHtml += "\n      <th style=\"\n        padding: 12px;\n        border: 1px solid #ddd;\n        text-align: left;\n      \">\n        " + Ember.Handlebars.Utils.escapeExpression(col) + "\n      </th>\n    ";
        });

        tableHtml += "</tr></thead><tbody>";

        body.forEach(function (row, index) {
            var rowBg = index % 2 === 0 ? "#f8f9fa" : "#ffffff"; // Zebra striping
            tableHtml += "<tr style=\"background-color: " + rowBg + ";\">";

            row.forEach(function (col) {
                tableHtml += "\n        <td style=\"\n          padding: 10px;\n          border: 1px solid #ddd;\n          text-align: left;\n        \">\n          " + Ember.Handlebars.Utils.escapeExpression(col) + "\n        </td>\n      ";
            });

            tableHtml += "</tr>";
        });

        tableHtml += "</tbody></table>";

        return new Ember.String.htmlSafe(tableHtml);
    });
});