define('due-backoffice/components/companies/segments/customer-journeys-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({

    childClassNames: "ui large list",
    new_target: 'customer-journeys.new',

    customer_journeys: Ember.computed.filterBy('model.customer_journeys', 'isNew', false),

    title: Ember.computed('model.customerJourneyCount', function () {
      return 'Customer Journeys (' + this.get('model.customerJourneyCount') + ')';
    })

  });
});