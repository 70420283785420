define("due-backoffice/templates/customer-journeys/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 6
          }
        },
        "moduleName": "due-backoffice/templates/customer-journeys/new.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui segment");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["inline", "customer-journey/new-form", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 36], [2, 41]]]]], [], []], "fr_choosed", true, "modal_phrase", ["subexpr", "concat", ["Créer une nouveau customer journey (", ["get", "model.name", ["loc", [null, [2, 118], [2, 128]]]], ")?"], [], ["loc", [null, [2, 71], [2, 134]]]]], ["loc", [null, [2, 2], [2, 136]]]]],
      locals: [],
      templates: []
    };
  }());
});