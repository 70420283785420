define('due-backoffice/components/customer-journey/item-accordion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    is_active: false,
    indexActive: { 0: 'active' },

    willDestroyElement: function willDestroyElement() {
      $('#delete-customer-journey-' + this.get('model.id')).remove();
    },


    is_active_class: Ember.computed('is_active', function () {
      return this.get('is_active') ? 'active' : '';
    }),

    actions: {
      toogleActive: function toogleActive() {
        this.toggleProperty('is_active');
      },
      editCustomerJourney: function editCustomerJourney(customer_journey_id) {
        this.get('router').transitionTo('customer-journeys.edit', customer_journey_id);
      },
      deleteCustomerJourney: function deleteCustomerJourney() {
        var _this = this;

        $('#delete-customer-journey-' + this.get('model.id')).modal({
          onApprove: function onApprove() {
            return _this.get('model').destroyRecord();
          }
        }).modal('show');
      }
    }
  });
});